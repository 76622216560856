<template>
  <svg width="18"
       height="18"
       viewBox="0 0 18 18"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9"
            cy="5.5"
            r="3.5"
            stroke="#9e9e9e"
            stroke-width="2"
    />
    <path stroke="#9e9e9e" stroke-width="2" d="M14.757 14.02C15.027 15.09 14.105 16 13 16H5c-1.105 0-2.027-.91-1.756-1.98C3.976 11.117 6.276 9 9 9s5.024 2.118 5.757 5.02z" />
  </svg>
</template>

<script>
    export default {
        name: "Nickname",
    }
</script>
